<section class="landing">
  <div class="left">
    <a href="http://knowwheregraph.org/"
      ><img
        src="../assets/images/svgs/logo-main.svg"
        alt="Know Where Graph Logo"
    /></a>
  </div>
  <div class="right" id="menuToggle">
    <input type="checkbox" />

    <ul class="nav" id="menu">
      <li class="nav-item">
        <a href="https://www.knowwheregraph.org/graph/">
          <p class="item">The Graph</p>
        </a>
      </li>
      <li class="nav-item">
        <a href="https://www.knowwheregraph.org/tools/">
          <p class="item">Tools</p>
        </a>
      </li>
      <li class="nav-item">
        <a href="https://www.knowwheregraph.org/pilots/">
          <p class="item">Pilots</p>
        </a>
      </li>
      <li class="nav-item">
        <a href="https://www.knowwheregraph.org/people/">
          <p class="item">People</p>
        </a>
      </li>
      <li class="nav-item">
        <a href="https://www.knowwheregraph.org/publications/">
          <p class="item">Publications</p>
        </a>
      </li>
      <li class="nav-item">
        <a href="https://www.knowwheregraph.org/about/">
          <p class="item">About</p>
        </a>
      </li>
      <li class="nav-item">
        <a href="https://www.knowwheregraph.org/contact/">
          <p class="item">Contact</p>
        </a>
      </li>
    </ul>
  </div>
  <div class="nav-display">
    <label class="breadcrumb">{{ breadcrumb }}</label>
    <label class="pagename">{{ pageName }}</label>
  </div>
</section>
