<div class="table-container">
  <mat-paginator
    class="paginator"
    #paginator
    [pageSize]="pageSize"
    [pageSizeOptions]="[20, 50, 100]"
    [showFirstLastButtons]="true"
    [length]="totalSize"
    [pageIndex]="currentPage"
  >
  </mat-paginator>
  <mat-table [dataSource]="people" class="search-table">
    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Name
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span class="overflow-cell">
          <a href="{{ row.name_uri }}" target="_blank">{{ row.name }}</a>
        </span>
      </mat-cell>
    </ng-container>

    <!-- Affiliation Column -->
    <ng-container matColumnDef="affiliation">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Affiliation
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span class="overflow-cell">
          <a [href]="row.affiliation_uri" target="_blank">{{
            row.affiliation
          }}</a>
        </span>
      </mat-cell>
    </ng-container>

    <!-- Expertise Column -->
    <ng-container matColumnDef="expertise">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        class="expertise-header"
      >
        Expertise
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span class="overflow-cell">
          <a
            *ngFor="let funct of row.expertise; let isLast = last"
            [href]="funct[0]"
            target="_blank"
            >{{ funct[1] }}{{ isLast ? '' : ', ' }}</a
          >
        </span>
      </mat-cell>
    </ng-container>

    <!-- Place Column -->
    <ng-container matColumnDef="place">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Place
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span class="overflow-cell">
          <a [href]="row.place_uri" target="_blank">{{ row.place }}</a>
        </span>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="peopleColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: peopleColumns"></mat-row>
  </mat-table>
</div>
