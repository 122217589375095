import { Component } from '@angular/core'

/**
 * The main application component.
 */
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'Knowledge Explorer'
}
