<div class="table-container">
  <mat-paginator
    class="paginator"
    #paginator
    [pageSize]="pageSize"
    [pageSizeOptions]="[20, 50, 100]"
    [showFirstLastButtons]="true"
    [length]="totalSize"
    [pageIndex]="currentPage"
  ></mat-paginator>
  <mat-table [dataSource]="placesDataSource" class="results-table">
    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        class="place-name-header"
      >
        Name
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span class="overflow-cell">
          <a [href]="row.nameUri" target="_blank">{{ row.name }}</a>
        </span>
      </mat-cell>
    </ng-container>

    <!-- Type Column -->
    <ng-container matColumnDef="type">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        class="place-type-header"
      >
        Type
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span class="overflow-cell">
          <a [href]="row.typeUri" target="_blank">{{ row.type }}</a>
        </span>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="placesColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: placesColumns"></mat-row>
  </mat-table>
</div>
